body,
#root,
.App {
  margin: 0;
  padding: 0;
  font-family: "Roboto Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

.App {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 3em;
  text-align: center;
}

.App input {
  margin-bottom: 2em;
  width: 5em;
}

.explanation {
  margin-bottom: 1em;
}

.App table {
  margin: auto;
}

.num-even {
  background-color: #eeeeee;
}
